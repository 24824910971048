export class Preguntas {
   public id: number;
    pre: string;
    re1: string;
    re2: string;
    re3: string;
    re4: string;
    crt:number;// correcta
    pos:number;
    ele:number;
    rl:string;// retroalimentación
    tp:number;//tipo de pregunta
    cat:string;//categoria de la pregunta distrital basica etc
}
