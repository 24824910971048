import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CuestionarioComponent } from './cuestionario/cuestionario.component';
import { DetallesComponent } from './detalles/detalles.component';
import { ResaltarDiretive } from './directives/directives..directive';
import { InicioComponent } from './inicio/inicio/inicio.component';
import { PresentacionComponent } from './presentacion/presentacion.component';
import { CrearPreguntaComponente } from './preguntas/crearpregunta.component';
import { CrearPreguntaComponenteTipoTres } from './preguntast3/crearpregunta.component';
import { LoginComponent } from './login/login.component';
import { RegistroComponent } from './registro/registro.component';
import { RestablecerComponent } from './restablecer/restablecer.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PreguntasService } from './servicios/preguntas.service';
import { AutorizacionService } from './servicios/autorizacion.service';
import { GuardianService } from './servicios/guardian.service';
import { PerfilesService } from './servicios/perfiles.service';
import { GuardianAdminService } from './servicios/guardianadmin.service';
import { GuardianSuscripcionService } from './servicios/guardian-suscripcion.services';
import { ComunesService } from './utilitarios/comunes/comunes.service';
import {MatCheckboxModule, MatMenuModule, MatInput, MatInputModule, MatRadioModule, MatButtonModule, MatCardModule, MatTabsModule, MatListModule, MatExpansionPanel, MatExpansionModule,MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatFormFieldModule, MatGridListModule} from '@angular/material';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { SimuladorComponent } from './cuestionario/simulador.component.';
import { QuienQuiereSerFuncionarioComponent } from './cuestionario/quienquiereserfuncionario.component';
import { ActivarSuscripcionComponente } from './usuario/usuario.component';
import { MessagingService } from './servicios/messaging.service';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { NotificacionesService } from './servicios/notificaciones.services';
import { MaterialesService } from './servicios/materiales.services ';

import * as firebase from 'firebase/app';
import { MaterialesComponent } from './materiales/materiales.component';
@NgModule({
  declarations: [
    AppComponent,
    ResaltarDiretive,
    DetallesComponent,
    CrearPreguntaComponente,
    CrearPreguntaComponenteTipoTres,
    PresentacionComponent,
    LoginComponent,
    RegistroComponent,
    CuestionarioComponent,
    RestablecerComponent,
    InicioComponent,
    SimuladorComponent,
    QuienQuiereSerFuncionarioComponent,
    SanitizeHtmlPipe,
    ActivarSuscripcionComponente,
    NotificacionesComponent,
    MaterialesComponent,


  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    MatButtonModule,
    MatTabsModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDialogModule,
    MatGridListModule,
    MatInputModule,
    HttpClientModule ,

    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    NgxChartsModule,
    ChartsModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [PreguntasService,
    AutorizacionService,
    NotificacionesService,
    GuardianService,
    PerfilesService,
    GuardianAdminService,
    MaterialesService,
    GuardianSuscripcionService,ComunesService,MessagingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
