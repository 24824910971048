import { Directive, OnInit, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[resaltar]',
  
})
export class ResaltarDiretive implements OnInit {

  constructor(private elRef:ElementRef,private renderer:Renderer2) { }
@Input ('resaltar') plan : string ='';
  ngOnInit() {
    if(this.plan =='pagado'){
      /** Recibe tres para,etros 
       * 1 Elemento nativo
       * 2 Atributo de css que se va a cambiar
       * 3 Valor del atributo */
this.renderer.setStyle(this.elRef.nativeElement,'background-color','yellow');
this.renderer.setStyle(this.elRef.nativeElement,'font-weight','bold');


    }
  }

}
