import { Injectable } from '@angular/core';
import { Materiales } from '../clases/materiales.model';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class MaterialesService { 

  constructor(private afDB: AngularFireDatabase,private  angularFireAuth : AngularFireAuth) { }

  public getTodasMateriales(nodo:string) {
    var max:number =2;
    //return this.afDB.list('preguntas/');
    //let startIndex= ''+(Math.random()*max+1)
   // return this.afDB.list(nodo+'/',ref=> ref.orderByChild('est').startAt('a').limitToFirst(parseInt(startIndex)).limitToLast(parseInt(startIndex)));

    return this.afDB.list(nodo+'/');
  } 
  public guardarMateriales(material:Materiales) {
      this.afDB.database.ref('material/'+new Date().getTime()).set(material);
  }
  public ActualizarMateriales(key:number,material:Materiales) {
    
    
    
    this.afDB.database.ref('material/'+key).update(material);
  }
  public obtenerMateriales() {
    return this.afDB.list('material/',ref=> ref.orderByChild("cid").equalTo(this.angularFireAuth.auth.currentUser.email
      ));
    //return this.afDB.object('preguntas/'+numero);
    
   }
   public obtenerPerfilKey(correo:string) {
     var resultadoFinal;
     this.afDB.database.ref('perfiles/').orderByChild("cr").equalTo(correo).on("child_added",(resultado)=>{
    resultadoFinal=resultado
    });
    return resultadoFinal;
    //return this.afDB.object('preguntas/'+numero);
   }
   public obtenerPerfil(correo:string) {
    return this.afDB.list('perfiles/',ref=> ref.orderByChild("cr").equalTo(correo));
    //return this.afDB.object('preguntas/'+numero);
   }
  claveUsuario(email:string){
    let clave:string;
    let emailAux=email.toLowerCase();
   for(let i=0;i<emailAux.length;i++)
   {
clave+=emailAux.charCodeAt(i);
   } 
   return parseInt(clave);
  }
}


