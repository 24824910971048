import { Component, OnInit } from '@angular/core';
import { AutorizacionService } from '../servicios/autorizacion.service';
import { Perfiles } from '../clases/perfiles.model';
import { PerfilesService } from '../servicios/perfiles.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
 registro:any={};
 perfiles:Perfiles;
  constructor(public autorizacion:AutorizacionService,
    private perfilesService:PerfilesService) {
    //this.autorizacion.registro('login','passwor');
   }
public registrarUsuario(){
  this.autorizacion.registro(this.registro.email,this.registro.password,this.registro.nombre);
  this.registro.email='';
  this.registro.nombre='';
  this.registro.password='';
}
  ngOnInit() {
  }

}
