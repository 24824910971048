import { Component, OnInit } from '@angular/core';
import { PreguntasService } from '../servicios/preguntas.service';
import { Preguntas } from '../clases/preguntas.model';

@Component({
  selector: 'app-detalles',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.css']
})
export class DetallesComponent implements OnInit {
  objPreguntas=new Preguntas;
private id:any={};
idPregunta=null
  constructor(private preguntasService:PreguntasService) {
    this.preguntasService.getIdUltimaPregunta("basicas").valueChanges()
    .subscribe
    (data=>{
      data.map((e: any = {}) => {
        this.objPreguntas = {
          id: e.id
          
        } as Preguntas;
      }
      )
 });
   }
plan='pagado';
  ngOnInit() {
  }
idpreguntajj(){
 //this.id=this.preguntasService.idPregunta(); 
}
}
