import { Injectable } from '@angular/core';
import { Perfiles } from '../clases/perfiles.model';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class PerfilesService {

  constructor(private afDB: AngularFireDatabase,private  angularFireAuth : AngularFireAuth) { }

  public guardarPerfil(perfiles:Perfiles) {
    
    
    
    this.afDB.database.ref('perfiles/'+new Date().getTime()).set(perfiles);
  }
  public ActualizarPerfil(key:number,perfiles:Perfiles) {
    
    
    
    this.afDB.database.ref('perfiles/'+key).update(perfiles);
  }
  public obtenerPerfilUsuarioActual() {
    return this.afDB.list('perfiles/',ref=> ref.orderByChild("cr").equalTo(this.angularFireAuth.auth.currentUser.email
      ));
    //return this.afDB.object('preguntas/'+numero);
    
   }
   public obtenerPerfilKey(correo:string) {
     var resultadoFinal;
     this.afDB.database.ref('perfiles/').orderByChild("cr").equalTo(correo).on("child_added",(resultado)=>{
    resultadoFinal=resultado
    });
    return resultadoFinal;
    //return this.afDB.object('preguntas/'+numero);
   }
   public obtenerPerfil(correo:string) {
    return this.afDB.list('perfiles/',ref=> ref.orderByChild("cr").equalTo(correo));
    //return this.afDB.object('preguntas/'+numero);
   }
  claveUsuario(email:string){
    let clave:string;
    let emailAux=email.toLowerCase();
   for(let i=0;i<emailAux.length;i++)
   {
clave+=emailAux.charCodeAt(i);
   } 
   return parseInt(clave);
  }
}
