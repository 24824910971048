import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetallesComponent } from './detalles/detalles.component';
import { CrearPreguntaComponente } from './preguntas/crearpregunta.component';
import { PresentacionComponent } from './presentacion/presentacion.component';
import { LoginComponent } from './login/login.component';
import { RegistroComponent } from './registro/registro.component';
import { GuardianService } from './servicios/guardian.service';
import { CuestionarioComponent } from './cuestionario/cuestionario.component';
import { RestablecerComponent } from './restablecer/restablecer.component';
import { GuardianLoginService } from './servicios/guardian-login.service';
import { GuardianAdminService } from './servicios/guardianadmin.service';
import { InicioComponent } from './inicio/inicio/inicio.component';
import { GuardianSuscripcionService } from './servicios/guardian-suscripcion.services';
import { SimuladorComponent } from './cuestionario/simulador.component.';
import { QuienQuiereSerFuncionarioComponent } from './cuestionario/quienquiereserfuncionario.component';
import {  ActivarSuscripcionComponente } from './usuario/usuario.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { CrearPreguntaComponenteTipoTres } from './preguntast3/crearpregunta.component';
import { MaterialesComponent } from './materiales/materiales.component';

const routes: Routes = [{ path:'', redirectTo: '/inicio',
pathMatch: 'full'},


{
  path:
 'detalles', component: DetallesComponent },
{ path: 'crearpreguntas', component: CrearPreguntaComponente,canActivate:[GuardianService,GuardianAdminService] },
{ path: 'crearpreguntastipotres', component: CrearPreguntaComponenteTipoTres,canActivate:[GuardianService,GuardianAdminService] },
{ path: 'inicio', component: InicioComponent },
{ path: 'acerca', component: PresentacionComponent },
{ path: 'login', component: LoginComponent,canActivate:[GuardianLoginService]  },
{ path: 'registro', component: RegistroComponent,canActivate:[GuardianLoginService]  },
{ path: 'restablecer', component: RestablecerComponent,canActivate:[GuardianLoginService]  },
{ path: 'cuestionario', component: CuestionarioComponent,canActivate:[GuardianSuscripcionService] },
{ path: 'cuestionarior', component: CuestionarioComponent,canActivate:[GuardianSuscripcionService] },
{ path: 'simularpruebaescrita', component: SimuladorComponent,canActivate:[GuardianSuscripcionService] },
{ path: 'simularpruebaescritar', component: SimuladorComponent,canActivate:[GuardianSuscripcionService] },
{ path: 'quienquiereserfuncionario', component: QuienQuiereSerFuncionarioComponent,canActivate:[GuardianSuscripcionService] },
{ path: 'quienquiereserfuncionarior', component: QuienQuiereSerFuncionarioComponent,canActivate:[GuardianSuscripcionService] },
{ path: 'activarsuscripcion', component: ActivarSuscripcionComponente,canActivate:[GuardianSuscripcionService] },
{ path: 'materiales', component: MaterialesComponent,canActivate:[GuardianSuscripcionService] },
{ path: 'notificaciones', component: NotificacionesComponent, },







 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
