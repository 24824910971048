import { Component, OnInit } from '@angular/core';
import { PreguntasService } from '../servicios/preguntas.service';
import { ComunesService } from '../utilitarios/comunes/comunes.service';
import { Preguntas } from '../clases/preguntas.model';
import { AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-presentacion',
  templateUrl: './crearpregunta.component.html',
  styleUrls: ['./crearpregunta.component.css']
})
export class CrearPreguntaComponente implements OnInit {
  preguntas= new Preguntas();
  objPreguntas: Preguntas[];
  objPreguntas1:any={}; 
  private $arrayPreguntas=[];
  numPreguntas=[];
  id:any={};
  mensaje:string;
  error:string="";
  opcion:string="basicas";
  constructor(private preguntaService:PreguntasService,
    public comunesService:ComunesService,private angularFireAuth : AngularFireAuth) { 
     
 
  
      }
  
    
  

    

  guardarPregunta(){
    if(
      this.preguntas.re1==null||
      this.preguntas.re2==null||
      this.preguntas.re3==null||
      this.preguntas.re4==null||
      this.preguntas.pre==null||
      this.preguntas.tp==null
      ||
      this.preguntas.crt==null
      ){
        this.error="Error";
        this.mensaje="Debe rellenar todos los campos"
      }else{
        this.preguntaService.guardarPregunta(this.preguntas,this.opcion);
        this.error="Exito";
        this.mensaje="Pregunta Guardada con éxito"

      }
   
     
  }
  limpiar(){
    this.preguntas.re1=''
    this.preguntas.re2=''
    this.preguntas.re3=''
    this.preguntas.re4=''
    this.preguntas.pre=''
    this.preguntas.rl=''

  }
  ngOnInit() {
   
this.preguntas.tp=1;
    
  }
numeroPreguntas(){
  var minimoPreguntas = 1;
  var maximoPreguntas = 60;
  var totalPreguntas= 55
  this.id=this.comunesService.numeroPreguntas(minimoPreguntas,
    maximoPreguntas,totalPreguntas);

}
idpre2(i:number){

  this.objPreguntas1=this.preguntaService.obtenerPregunta(this.opcion,i).valueChanges()
   .subscribe
   (data=>{
    this.objPreguntas1=data
    this.$arrayPreguntas.push(this.objPreguntas1)
    
   }
   );  
}
idpre(){
  for(let i=1;i<4;i++){
  this.idpre2(i)

};
}

}

