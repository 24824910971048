import { Injectable, ErrorHandler, PipeTransform, Pipe } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComunesService extends ErrorHandler{


  mensaje:string;
  nombre:string;
  configuracion:any = [];
  titulos:any = [];
  letras:any = [];
  fireBaseUrl:string="https://entrenamientocon-cc172.web.app/";
  constructor(private http: HttpClient) { 
    super();
    this.getJSON().subscribe(data => {
      this.configuracion=data['opciones']
      this.titulos=data['titulos'];
      this.letras=data['letras'];
      
  });
  }

public ExceptionUsuario(mensaje) {
  super.handleError("ExceptionUsuario: " +mensaje);

}
public getJSON() {
  return this.http.get("./configuracion.json",{responseType: 'json'})
  
}
public numeroPreguntasValidas(){

}

 /**Retorna el numero de preguntas a realizar */
 public numeroPreguntas(minimoPreguntas:number,
  maximoPreguntas:number,
  totalPreguntas:number) {

  //var minimoPreguntas = 1;
  //var maximoPreguntas = 60;
  //var totalPreguntas= 55;//debe ser menor a maximo de preguntas
  if(minimoPreguntas>maximoPreguntas){
    this.ExceptionUsuario("Preguntas minimas no deben ser mayor a las maximas");
      throw this.ExceptionUsuario;
  }else if(maximoPreguntas<=totalPreguntas){
    this.ExceptionUsuario("Total de preguntas debe ser menor o igual a maximo de preguntas");
    throw this.ExceptionUsuario;
  }

  var myArray = [];
  while (myArray.length < totalPreguntas) {
    var numeroAleatorio = Math.ceil(Math.random() * (maximoPreguntas - minimoPreguntas) + minimoPreguntas);
    var existe = false;
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i] == numeroAleatorio) {
        existe = true;
        break;
      }
    }
    if (!existe) {
      myArray[myArray.length] = numeroAleatorio;
    }

  }
  return myArray;

 //return this.afDB.database.ref('preguntas').orderByKey().endAt("id").limitToLast(1);
}

 /**Retorna el numero de preguntas a realizar */
 public numeroPreguntasTipo(minimoPreguntas:number,
  maximoPreguntas:number,
  totalPreguntas:number,tipoPregunta:string) {

  //var minimoPreguntas = 1;
  //var maximoPreguntas = 60;
  //var totalPreguntas= 55;//debe ser menor a maximo de preguntas
  if(minimoPreguntas>maximoPreguntas){
    this.ExceptionUsuario("Preguntas minimas no deben ser mayor a las maximas");
      throw this.ExceptionUsuario;
  }else if(maximoPreguntas<=totalPreguntas){
    this.ExceptionUsuario("Total de preguntas debe ser menor o igual a maximo de preguntas");
    throw this.ExceptionUsuario;
  }
  var jsonArg1 = new Object();
  var myArray = [];
  while (myArray.length < totalPreguntas) {
    var numeroAleatorio = Math.ceil(Math.random() * (maximoPreguntas - minimoPreguntas) + minimoPreguntas);
    var existe = false;
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].numeroAleatorio == numeroAleatorio) {
        existe = true;
        break;
      }
    }
    if (!existe) {
      
      myArray.push({tipoPregunta,numeroAleatorio});
    }

  }
  return myArray;

 //return this.afDB.database.ref('preguntas').orderByKey().endAt("id").limitToLast(1);
}
shuffle(array) {
  let counter = array.length;

  // While there are elements in the array
  while (counter > 0) {
      // Pick a random index
      let index = Math.floor(Math.random() * counter);

      // Decrease counter by 1
      counter--;

      // And swap the last element with it
      let temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
  }

  return array;
}

}
