import { Component, OnInit, Pipe } from '@angular/core';
import { PreguntasService } from '../servicios/preguntas.service';
import { ComunesService } from '../utilitarios/comunes/comunes.service';
import { AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { MessagingService } from '../servicios/messaging.service';
import { NotificacionesService } from '../servicios/notificaciones.services';
import { Notificaciones } from '../clases/notificaciones.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-presentacion',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})


export class NotificacionesComponent implements OnInit {
  notificaciones=null;
  objPreguntas1:any={}; 
  private $arrayPreguntas=[];
  numPreguntas=[];
  id:any={};
  mensaje:any={};
  error:string="";
  opcion:string="basicas";
  constructor(private preguntaService:PreguntasService,
    public comunesService:ComunesService,private angularFireAuth : AngularFireAuth,
     private notificacionesServices:NotificacionesService,
     private sanitizer:DomSanitizer) { 
     
     // this.messagingService.getPermission();
    //  this.messagingService.receiveMessage();
     // this.mensaje=this.messagingService.currentMessage;
     this.notificacionesServices.getTodasNotificaciones("notificaciones").
      valueChanges()
      .subscribe
      ((data) => {
        
         this.notificaciones=data
        
        

      });

      }
  
    
satinizar(html){
  return this.sanitizer.bypassSecurityTrustHtml(html);

}

 
  ngOnInit() {
    
   // this.mensaje=this.messagingService.currentMessage;

  }




}

