import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Preguntas } from '../clases/preguntas.model';
import { PreguntasService } from '../servicios/preguntas.service';
import { ComunesService } from '../utilitarios/comunes/comunes.service';
import { GuardianAdminService } from '../servicios/guardianadmin.service';
import { Perfiles } from '../clases/perfiles.model';
import { PerfilesService } from '../servicios/perfiles.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { trigger, state, style } from '@angular/animations';

@Component({
  selector: 'app-cuestionario',
  templateUrl: './cuestionario.component.html',
  styleUrls: ['./cuestionario.component.css'],
  animations: [trigger('contenedorAnimable', [
    state('inicial',style({
      opacity:0,
      backgroundColor:'green'
    }
      ))
  ]
  )
]
})

export class CuestionarioComponent implements OnInit  {
  textoPregunta:string="";
  index:number=0;
  objPreguntas = new Preguntas;
  objPreguntas2 = new Preguntas;
  objPreguntasArray: Preguntas[];
  arrayPreguntas = [];
  recupera: any = {};
  arrayNumeroPreguntas = [];
  intNumeroPregunta = 0;
  contadorPreguntas: number = 0;
  retrocerPreguntas: number = 0;
  btnRegresar: boolean = false;
  btnSiguiente: boolean = false;
  btnSiguienteInicio: boolean = false;
  btnTerminar: boolean = false;
  btninicio: boolean = true;
  resultados: boolean = false;
  perfiles: Perfiles;
  letras: any = { 0: "A", 1: "B", 2: "C", 3: "D", 4: "E", 5: "F" }
  tipoPregunta: any = {}
  btninicioNuevamente: boolean = false;
  intPregunta: number;
  mostrarRes = false;
  opcion: string = "basicas";
  view: number[] = [250, 400];
  data: any[] = [];
  data2 = [];
  isRealtime: boolean;
  showXAxisLabel: boolean;
  showYAxisLabel: boolean;
  showLegend: boolean;
  interval: number;
  mostrarGrafico2d=false;
  mostrarGraficoBar=false;
  colorScheme = [
  {name:"Correctas",value:'#00FF00'},
  {name:"Incorrectas",value:'#FF0000'}
];
  preguntasAMostrar: number = 30;

  constructor(private preguntaService: PreguntasService,
    private comunesService: ComunesService, 
    private router: Router,
    private perfilesService: PerfilesService,
    private angularFireAuth: AngularFireAuth,
    
    ) {
      //bottom
      //below
    this.perfilesService.obtenerPerfil(this.angularFireAuth.auth.currentUser.email).
      valueChanges()
      .subscribe
      (data => {
        data.map((e: any = {}) => {
          this.perfiles = {
            cr: e.cr,
            fe: e.fe,
            nb: e.nb,
            rl: e.rl

          } as Perfiles;
        }
        )

      });
  }
 
  ngOnInit() {



  }
  getPregunta() {
    this.btnRegresar = true;
    this.intNumeroPregunta++;
    this.btnSiguiente = false
    this.guardarResultados();

    this.contadorPreguntas++;
    if (this.contadorPreguntas + 1 == this.arrayNumeroPreguntas.length) {
      this.btnSiguienteInicio = false;
      this.btnTerminar = true;
    }
    if (this.contadorPreguntas < this.arrayNumeroPreguntas.length) {



      if (this.contadorPreguntas < this.arrayNumeroPreguntas.length
        && this.intNumeroPregunta < this.arrayNumeroPreguntas.length) {
        this.preguntaService.obtenerPregunta(this.opcion,
          this.arrayNumeroPreguntas[this.intNumeroPregunta]).
          valueChanges()
          .subscribe
          (data => {
            data.map((e: any = {}) => {
              this.objPreguntas = {
                id: e.id,
                pre: e.pre,
                re1: e.re1,
                re2: e.re2,
                re3: e.re3,
                re4: e.re4,
                crt: e.crt,
                rl: e.rl,
                tp: e.tp,
                pos: this.intNumeroPregunta
              } as Preguntas;
            }
            )
            this.objPreguntas.ele = 1;

          });
      }

    }

  }
  iniciarPrueba() {
    this.preguntaService.getIdUltimaPregunta(this.opcion).valueChanges()
      .subscribe
      (data => {
        data.map((e: any = {}) => {
          this.objPreguntas2 = {
            id: e.id

          } as Preguntas;
          if (this.objPreguntas2.id <= this.preguntasAMostrar) {
            this.preguntasAMostrar = this.objPreguntas2.id - 1
          }
          this.arrayNumeroPreguntas = this.comunesService.numeroPreguntas(0, this.objPreguntas2.id, this.preguntasAMostrar);
        }

        )
      });
    this.resultados = false;
    if (this.preguntasAMostrar == 1) {
      this.btnTerminar = true;
    }
    if (this.contadorPreguntas < this.arrayNumeroPreguntas.length) {
      this.btnRegresar = false;
      this.btninicio = false;
      this.btnSiguienteInicio = true;

      if (this.contadorPreguntas < this.arrayNumeroPreguntas.length) {
        this.preguntaService.obtenerPregunta(this.opcion,
          this.arrayNumeroPreguntas[this.contadorPreguntas]).
          valueChanges()
          .subscribe
          (data => {
            data.map((e: any = {}) => {
              this.objPreguntas = {
                id: e.id,
                pre: e.pre,
                re1: e.re1,
                re2: e.re2,
                re3: e.re3,
                re4: e.re4,
                crt: e.crt,
                rl: e.rl,
                tp: e.tp,
                pos: 0
              } as Preguntas;
            }
            )
            this.objPreguntas.ele = 1;
          }
          );


      }

    }


  }
  regresar() {


    this.btnSiguiente = true
    /*  var result = Object.keys(this.arrayPreguntas).map(e=>this.arrayPreguntas[e]);
    console.log("Ok"+ this.objPreguntas.pos)
    this.objPreguntas={
   id: result[this.objPreguntas.pos].id,
   pregunta: result[this.objPreguntas.pos].pregunta,
   respuesta1: result[this.contadorPreguntas].respuesta1,
   respuesta2: result[this.contadorPreguntas].respuesta2,
   respuesta3: result[this.contadorPreguntas].respuesta3,
   respuesta4: result[this.contadorPreguntas].respuesta4,
   correcta: result[this.contadorPreguntas].correcta,
   pos: result[this.contadorPreguntas].pos
 };*/

    this.guardarResultados();
    if (this.objPreguntas.pos > 0)
      this.contadorPreguntas--;

    //console.log("Mi conteo " + this.contadorPreguntas);

    // this.objPreguntas=this.arrayPreguntas[this.contadorPreguntas--];
    this.objPreguntas = this.arrayPreguntas[this.contadorPreguntas];
    //console.log("Mi conteo " + this.contadorPreguntas);
    let myObjStr = JSON.stringify(this.arrayPreguntas);
    //console.log(this.objPreguntasArray)
    //console.log(myObjStr)
    //console.log(myObjStr.hasOwnProperty("id"))

    //console.log(this.objPreguntas)

    //console.log("Mi conteo 2" + this.contadorPreguntas);



  }
  siguiente() {
    this.contadorPreguntas++;
    if (this.contadorPreguntas >= this.arrayPreguntas.length) {
      this.btnTerminar = true;

    }


    this.objPreguntas = this.arrayPreguntas[this.contadorPreguntas];
    this.guardarResultados();



  }
  guardarResultados() {

    this.arrayPreguntas[this.objPreguntas.pos] = this.objPreguntas;

  }
  terminar() {
    this.guardarResultados();
    this.llenarGrafico();
    this.btnRegresar = false;
    this.contadorPreguntas = 0;
    this.btnSiguiente = true;
    this.btnSiguienteInicio = false;
    this.intNumeroPregunta = 0;
    this.resultados = true;
    this.btnTerminar = false;
    this.btninicioNuevamente = true;
  }
  iniciarNuevamente() {
    if (location.pathname == "/cuestionario") {
      this.router.navigate(["cuestionarior"])
    } else {
      this.router.navigate(["cuestionario"])
    }
  }
  mostrarPregunta(numeroPregunta) {

    this.objPreguntas = this.arrayPreguntas[numeroPregunta];
    this.intPregunta = numeroPregunta + 1;
    this.mostrarRes = true;
    this.textoPregunta=this.intPregunta+": "+this.objPreguntas.pre;

  }
  llenarGrafico()
{
 
  let  jsonS = {
    instructions: [],
    
   
};
let  jsonData2 = {
  instructions: [],
  
 
};
let  jsonData3 = {
  instructions: [],
  
 
};
let correctaTipo1:number=0;
let incorrectaTipo1:number=0;
let correctaTipo2:number=0;
let incorrectaTipo2:number=0;
let correctaTipo3:number=0;
let incorrectaTipo3:number=0;


  for(var i=0;i<this.arrayPreguntas.length;i++) {
    let preguntaAux:Preguntas;
    
    preguntaAux=this.arrayPreguntas[i];
    if(preguntaAux.crt==preguntaAux.ele&&preguntaAux.tp==1){
      correctaTipo1++
    }else if(preguntaAux.tp==1){
      incorrectaTipo1++
    }
    if(preguntaAux.crt==preguntaAux.ele&&preguntaAux.tp==2){
      correctaTipo2++
    }else if(preguntaAux.tp==2){
      incorrectaTipo2++
    }
    if(preguntaAux.crt==preguntaAux.ele&&preguntaAux.tp==3){
      correctaTipo3++
    }else if(preguntaAux.tp==3){
      incorrectaTipo3++
    }
}
jsonData2.instructions.push({
  name: "Correctas",
  value:correctaTipo1+correctaTipo2+correctaTipo3
  
});
jsonData2.instructions.push({
  name: "Incorrectas",
  value:incorrectaTipo1+incorrectaTipo2+incorrectaTipo3
  
});
jsonS.instructions.push({
  name: "Tipo 1",
  series:[{name: "Incorrectas",
    value: incorrectaTipo1}]
  
})
jsonS.instructions.push({
  series:[{name: "Correctas",
    value: correctaTipo1}],name: "Tipo 1",
  
  
})

jsonS.instructions.push({
  name: "Tipo 2",
  series:[{name: "Incorrectas",
    value: incorrectaTipo2}]
  
})
jsonS.instructions.push({
  name: "Tipo 2",
  series:[{name: "Correctas",
    value: correctaTipo2}]
  
})
jsonS.instructions.push({
  name: "Tipo 3",
  series:[{name: "Incorrectas",
    value: incorrectaTipo3}]
  
})
jsonS.instructions.push({
  name: "Tipo 3",
  series:[{name: "Correctas",
    value: correctaTipo3}]
  
})

this.data2=jsonData2.instructions;
this.data=jsonS.instructions;
}

public barChartOptions = {
  scaleShowVerticalLines: false,
  responsive: true
};
muestraGrafico(){
  if(this.index==2){
    this.mostrarGrafico2d=true
    this.mostrarGraficoBar=false

  }else if(this.index==3){
    this.mostrarGraficoBar=true
    this.mostrarGrafico2d=false
  }
  else{
    this.mostrarGrafico2d=false
    this.mostrarGraficoBar=false

  }
}

}
