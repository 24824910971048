import { Injectable } from '@angular/core';
import { AutorizacionService } from './autorizacion.service';

@Injectable({
  providedIn: 'root'
})
export class GuardianLoginService {
  logeado=false;
  constructor (private autorizacion:AutorizacionService) {
    /*setTimeout(() => {
      this.listo=true;
    }, 3000);*/
    this.autorizacion.isLogeado()
    .subscribe((respuesta)=>{
      if(respuesta && respuesta.uid){
        this.logeado=false;
      }else{
        this.logeado=true;
  
      }
    },(error)=>{
      this.logeado=true;

    })

    }
    canActivate(){
      return this.logeado;
    }
}
