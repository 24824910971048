import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Preguntas } from '../clases/preguntas.model';

@Injectable({
  providedIn: 'root'
})
export class PreguntasService {
  private idPreguntas: any={};
  todos: AngularFireList<any[]>;
  idPregunta=new Preguntas;
  pregunta= new Preguntas;
  contador:number=1;
  constructor(private afDB: AngularFireDatabase) { 
    

  }

  public guardarPregunta(pregunta,nodo:string) {
    this.getIdUltimaPregunta(nodo).valueChanges()
   .subscribe
   (data=>{data.map((e:any={}) =>{
    this.idPregunta= {id: e.id,
      pre:e.pre,
      re1:e.re1,
      re2:e.re2,
      re3:e.re3,
      re4:e.re4,
      crt:e.crt,
    } as Preguntas;
    }
    
    )
      }
)
   ;
    this.afDB.database.ref(nodo+'/'+this.idPregunta.id + 1 * 1).once('value')
  .then((snapshot) => {
    if (snapshot.val() == null&&this.idPregunta.id==null) {
      pregunta.id=1;
      this.afDB.database.ref(nodo+'/'+ 1 ).set(pregunta);

      //this.afDB.database.ref('basicas/' + ()).set(pregunta);

     // this.afDB.database.ref('basicas/' + (this.idPregunta[0].id + 1 * 1)).set(pregunta);

    }else{
      pregunta.id=this.idPregunta.id + 1 * 1;
      this.afDB.database.ref(nodo+'/'+(this.idPregunta.id + 1 * 1)).set(pregunta);
    }
 }),((error)=>{

 });
    //this.afDB.database.ref('basicas/'+(this.idPregunta[0].id+1*1)).set(pregunta);

  }
 
  public getIdUltimaPregunta(nodo:string) {
    //return this.afDB.list('preguntas/');
    
    return this.afDB.list(nodo+'/', ref => 
    ref.limitToLast(1)
  );
  }  
 
  public obtenerPregunta(nodo:string,numero:number) {
  
 return this.afDB.list(nodo+'/',ref=> ref.orderByChild("id").equalTo(numero));

  }
  
  public idPreguntas2(){
    this.getIdUltimaPregunta("basicas").valueChanges().
    subscribe(lugares=>{
      
this.idPreguntas=lugares;
      
      
    });
    return this.idPreguntas;

  }
}
