import { Component, OnInit } from '@angular/core';
import { AutorizacionService } from 'src/app/servicios/autorizacion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 login:any={};
 

  constructor(public autorizacion:AutorizacionService,
    private router:Router) {
   }
public iniciarSesion(){
  this.autorizacion.login(this.login.email,this.login.password);
   
}
ngOnInit() {}

}
