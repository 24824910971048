import { Component, OnInit } from '@angular/core';
import { PreguntasService } from '../servicios/preguntas.service';
import { ComunesService } from '../utilitarios/comunes/comunes.service';
import { Preguntas } from '../clases/preguntas.model';
import { AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { PerfilesService } from '../servicios/perfiles.service';
import { Perfiles } from '../clases/perfiles.model';

@Component({
  selector: 'app-presentacion',
  templateUrl: './usuario.component.html',
 // styleUrls: ['../styles.css']
})
export class ActivarSuscripcionComponente implements OnInit {
  preguntas= new Preguntas();
  objPreguntas: Preguntas[];
  perfiles= new Perfiles();
  objPreguntas1:any={}; 
  private $arrayPreguntas=[];
  numPreguntas=[];
  id:any={};
  mensaje:string;
  correo:string;
  error:string="";
  opcion:string="basicas";
  key:number;
  meses = '1 2 3 4 5 6 7 8 9 10 11 12'.split(' ');
  selectedMes:number = 1;
  fechaActualSuscripcion:number;
  constructor(private preguntaService:PreguntasService,
    public comunesService:ComunesService,private angularFireAuth : AngularFireAuth,
    public perfilesService:PerfilesService) { 
     

  
      }
  
      activarUsuario(){
        this.perfilesService.ActualizarPerfil(this.key,this.perfiles)

      }
  
buscarUsuario(){
  var usuario;
  if(this.correo!=null)
      usuario= this.perfilesService.obtenerPerfilKey(this.correo)
      if(usuario!=null){
        this.perfiles=usuario.val();
        this.key=usuario.key;
        this.fechaActualSuscripcion=this.perfiles.fe
        var mifecha= new Date();
        mifecha.setTime(this.perfiles.fe)
        var fechaActual= new Date()
        if(mifecha.getTime()>fechaActual.getTime()){
          mifecha.setMonth(mifecha.getMonth()+this.selectedMes);
          this.perfiles.fe=mifecha.getTime();
        }else{
          fechaActual.setMonth(fechaActual.getMonth()+this.selectedMes);
          this.perfiles.fe=fechaActual.getTime();
        }
      }else{
        this.fechaActualSuscripcion=null
        this.perfiles.fe=null
        this.perfiles.nb=null
        this.perfiles.rl=null
        this.perfiles.cr=null
        this.key=null
      }
      
}
    

  onChange(newValue) {
    this.selectedMes = parseInt(newValue);
    var usuario;
  if(this.correo!=null)
      usuario= this.perfilesService.obtenerPerfilKey(this.correo)
      if(usuario!=null){
        this.perfiles=usuario.val();
        this.key=usuario.key;
        this.fechaActualSuscripcion=this.perfiles.fe
        var mifecha= new Date();
        mifecha.setTime(this.perfiles.fe)
        var fechaActual= new Date()
        if(mifecha.getTime()>fechaActual.getTime()){
          mifecha.setMonth(mifecha.getMonth()+this.selectedMes);
          this.perfiles.fe=mifecha.getTime();
        }else{
          fechaActual.setMonth(fechaActual.getMonth()+this.selectedMes);
          this.perfiles.fe=fechaActual.getTime();
        }
      }else{
        this.fechaActualSuscripcion=null
        this.perfiles.fe=null
        this.perfiles.nb=null
        this.perfiles.rl=null
        this.perfiles.cr=null
        this.key=null
      }
}

  guardarPregunta(){
    if(
      this.preguntas.re1==null||
      this.preguntas.re2==null||
      this.preguntas.re3==null||
      this.preguntas.re4==null||
      this.preguntas.pre==null||
      this.preguntas.tp==null
      ||
      this.preguntas.crt==null
      ){
        this.error="Error";
        this.mensaje="Debe rellenar todos los campos"
      }else{
        this.preguntaService.guardarPregunta(this.preguntas,this.opcion);
        this.error="Exito";
        this.mensaje="Pregunta Guardada con éxito"

      }
   
     
  }
  limpiar(){
    this.preguntas.re1=''
    this.preguntas.re2=''
    this.preguntas.re3=''
    this.preguntas.re4=''
    this.preguntas.pre=''
    this.preguntas.rl=''

  }
  ngOnInit() {
   
this.preguntas.tp=1;
    
  }
numeroPreguntas(){
  var minimoPreguntas = 1;
  var maximoPreguntas = 30;
  var totalPreguntas= 30
  this.id=this.comunesService.numeroPreguntas(minimoPreguntas,
    maximoPreguntas,totalPreguntas);

}
idpre2(i:number){

  this.objPreguntas1=this.preguntaService.obtenerPregunta(this.opcion,i).valueChanges()
   .subscribe
   (data=>{
    this.objPreguntas1=data
    this.$arrayPreguntas.push(this.objPreguntas1)
    
   }
   );  
}
idpre(){
  for(let i=1;i<4;i++){
  this.idpre2(i)

};
}

}

