import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { AutorizacionService } from './autorizacion.service';

@Injectable({
  providedIn: 'root'
})
export class GuardianService implements CanActivate{
  path: import("@angular/router").ActivatedRouteSnapshot[];
  route: import("@angular/router").ActivatedRouteSnapshot;
  logeado=false;
  constructor (private autorizacion:AutorizacionService) {
    /*setTimeout(() => {
      this.listo=true;
    }, 3000);*/
    this.autorizacion.isLogeado()
    .subscribe((respuesta)=>{
      if(respuesta && respuesta.uid){
        this.logeado=true;
      }else{
        this.logeado=false;
  
      }
    },(error)=>{
      this.logeado=false;

    })

    }
    canActivate(){
      return this.logeado;
    }
}
