import { Component, OnInit } from '@angular/core';
import { AutorizacionService } from '../servicios/autorizacion.service';

@Component({
  selector: 'app-registro',
  templateUrl: './restablecer.component.html',
  styleUrls: ['./restablecer.component.css']
})
export class RestablecerComponent implements OnInit {
 registro:any={};
  constructor(public autorizacion:AutorizacionService) {
    //this.autorizacion.registro('login','passwor');
   }
public restablecerUsuario(){
  this.autorizacion.restablecer(this.registro.email);
  this.registro.email='';
  this.registro.password='';
}
  ngOnInit() {
  }

}
