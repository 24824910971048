import { Component, OnInit } from '@angular/core';
import { Preguntas } from '../clases/preguntas.model';
import { PreguntasService } from '../servicios/preguntas.service';
import { ComunesService } from '../utilitarios/comunes/comunes.service';
import { Perfiles } from '../clases/perfiles.model';
import { PerfilesService } from '../servicios/perfiles.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { trigger, state, style } from '@angular/animations';
@Component({
  selector: 'app-cuestionario',
  templateUrl: './quienquiereserfuncionario.component.html',
  styleUrls: ['./cuestionario.component.css'],
  animations: [trigger('contenedorAnimable', [
    state('inicial', style({
      opacity: 0,
      backgroundColor: 'green'
    }
    ))
  ]
  )
  ]
})

export class QuienQuiereSerFuncionarioComponent implements OnInit {
  jsonSEstructura = {
    instructions: [],


  };
  textoPregunta:string="";
  controladorMostrares: boolean = false;
  checKDistritales: boolean;
  checKBasicas: boolean;
  checKMunicipales: boolean;
  index: number = 0;
  objPreguntas = new Preguntas;
  objPreguntas2 = new Preguntas;
  objPreguntasArray: Preguntas[];
  arrayPreguntas = [];
  recupera: any = {};
  arrayNumeroPreguntas = [];
  intNumeroPregunta = 0;
  contadorPreguntas: number = 0;
  retrocerPreguntas: number = 0;
  btnRegresar: boolean = false;
  btnSiguiente: boolean = false;
  btnSiguienteInicio: boolean = false;
  btnTerminar: boolean = false;
  btninicio: boolean = true;
  btnQuieroSeguir: boolean = false;
  btnCincuenta: boolean = true;
  btnSaltar: boolean = true;
  resultados: boolean = false;
  perfiles: Perfiles;
  acumulado:number=0;
  letras: any = { 0: "A", 1: "B", 2: "C", 3: "D", 4: "E", 5: "F" }
  tipoPregunta: any = {}
  arrayOcultar: any = {a:false,b:false,c:false,d:false}
  btninicioNuevamente: boolean = false;
  intPregunta: number;
  mostrarRes = false;
  opcion: string = "basicas";
  radiosPregunta :boolean=false;
  view: number[] = [250, 400];
  data: any[] = [];
  data2 = [];
  data3 = []
  isRealtime: boolean;
  showXAxisLabel: boolean;
  showYAxisLabel: boolean;
  showLegend: boolean;
  interval: number;
  mostrarGrafico2d = false;
  mostrarGraficoBar = false;
  mostrarGrafico2d3 = false
  colorScheme = [
    { name: "Correctas", value: '#00FF00' },
    { name: "Incorrectas", value: '#FF0000' }
  ];
  preguntasAMostrar: number = 30;
  intNumeroTotalPreguntas: number = 0
  constructor(private preguntaService: PreguntasService,
    private comunesService: ComunesService, private router: Router,

    private perfilesService: PerfilesService,
    private angularFireAuth: AngularFireAuth, ) {
    //bottom
    //below
    this.perfilesService.obtenerPerfil(this.angularFireAuth.auth.currentUser.email).
      valueChanges()
      .subscribe
      (data => {
        data.map((e: any = {}) => {
          this.perfiles = {
            cr: e.cr,
            fe: e.fe,
            nb: e.nb,
            rl: e.rl

          } as Perfiles;
        }
        )

      });
  }

  ngOnInit() {



  }
  
  

  saltarPregunta() {
    this.arrayOcultar = {a:false,b:false,c:false,d:false}
    this.btnSaltar=false;
    this.btnRegresar = true;
    this.btnQuieroSeguir = true;
    this.btnSiguiente = false

    //document.getElementById("op" + this.objPreguntas.ele).classList.remove('badge', 'badge-pill', 'badge-success');
    if (this.contadorPreguntas + 2 == this.arrayNumeroPreguntas.length) {
      this.btnSiguienteInicio = false;
      this.btnTerminar = true;
    }
    if (this.contadorPreguntas+1 < this.arrayNumeroPreguntas.length) {



      if (this.contadorPreguntas+1 < this.arrayNumeroPreguntas.length
        && this.intNumeroPregunta+1 < this.arrayNumeroPreguntas.length) {

        this.preguntaService.obtenerPregunta(this.arrayNumeroPreguntas[this.intNumeroPregunta+1].tipoPregunta,
          this.arrayNumeroPreguntas[this.intNumeroPregunta+1].numeroAleatorio).
          valueChanges()
          .subscribe
          (data => {
            data.map((e: any = {}) => {
              this.objPreguntas = {
                id: e.id,
                pre: e.pre,
                re1: e.re1,
                re2: e.re2,
                re3: e.re3,
                re4: e.re4,
                crt: e.crt,
                rl: e.rl,
                tp: e.tp,
                pos: this.intNumeroPregunta+1
              } as Preguntas;
            }
            )
            //this.objPreguntas.ele = 1;
            this.intNumeroPregunta++;
            this.contadorPreguntas++;
          });

        }

    }
  }
  getPregunta() {
    this.arrayOcultar = {a:false,b:false,c:false,d:false}

    this.btnRegresar = true;
    this.btnQuieroSeguir = true;
    this.intNumeroPregunta++;
    this.btnSiguiente = false
    this.guardarResultados();
    document.getElementById("op" + this.objPreguntas.ele).classList.remove('badge', 'badge-pill', 'badge-success');
    this.radiosPregunta=false;

    this.contadorPreguntas++;
    if (this.contadorPreguntas + 1 == this.arrayNumeroPreguntas.length) {
      this.btnSiguienteInicio = false;
      this.btnTerminar = true;
    }
    if (this.contadorPreguntas < this.arrayNumeroPreguntas.length) {



      if (this.contadorPreguntas < this.arrayNumeroPreguntas.length
        && this.intNumeroPregunta < this.arrayNumeroPreguntas.length) {

        this.preguntaService.obtenerPregunta(this.arrayNumeroPreguntas[this.intNumeroPregunta].tipoPregunta,
          this.arrayNumeroPreguntas[this.intNumeroPregunta].numeroAleatorio).
          valueChanges()
          .subscribe
          (data => {
            data.map((e: any = {}) => {
              this.objPreguntas = {
                id: e.id,
                pre: e.pre,
                re1: e.re1,
                re2: e.re2,
                re3: e.re3,
                re4: e.re4,
                crt: e.crt,
                rl: e.rl,
                tp: e.tp,
                pos: this.intNumeroPregunta
              } as Preguntas;
            }
            )
            //this.objPreguntas.ele = 1;

          });
      }

    }

  }
  iniciarPrueba() {
    let checKBasicasArray = [0]
    let checKDistritalesArray = []
    let checKMunicipalesArray = []
    let arrayFinal = []
    this.btnSiguiente = false;
    this.btnQuieroSeguir = true;
    if (this.arrayNumeroPreguntas.length == 0) {

      var categorias = document.forms['miformulario'];
      for (let x = 0; x < categorias.length; x++) {
        if (categorias[x].checked) {
          this.unirPreguntas(categorias[x].value)
        }
      }

    } else {
      this.intNumeroTotalPreguntas = this.arrayNumeroPreguntas.length

    }
    // this.arrayNumeroPreguntas.concat(unir)
    this.comunesService.shuffle(this.arrayNumeroPreguntas)
    this.resultados = false;
    if (this.preguntasAMostrar == 1) {
      this.btnTerminar = true;
    }
    if (this.contadorPreguntas < this.arrayNumeroPreguntas.length) {
      this.btnRegresar = false;
      this.btninicio = false;
      this.btnSiguienteInicio = true;

      if (this.contadorPreguntas < this.arrayNumeroPreguntas.length) {
        this.preguntaService.obtenerPregunta(this.arrayNumeroPreguntas[this.contadorPreguntas].tipoPregunta,
          this.arrayNumeroPreguntas[this.contadorPreguntas].numeroAleatorio).
          valueChanges()
          .subscribe
          (data => {
            data.map((e: any = {}) => {
              this.objPreguntas = {
                id: e.id,
                pre: e.pre,
                re1: e.re1,
                re2: e.re2,
                re3: e.re3,
                re4: e.re4,
                crt: e.crt,
                rl: e.rl,
                tp: e.tp,
                pos: 0
              } as Preguntas;
            }
            )
            //this.objPreguntas.ele = 1;
          }
          );


      }

    }


  }
  regresar() {


    this.btnSiguiente = true
    /*  var result = Object.keys(this.arrayPreguntas).map(e=>this.arrayPreguntas[e]);
    this.objPreguntas={
   id: result[this.objPreguntas.pos].id,
   pregunta: result[this.objPreguntas.pos].pregunta,
   respuesta1: result[this.contadorPreguntas].respuesta1,
   respuesta2: result[this.contadorPreguntas].respuesta2,
   respuesta3: result[this.contadorPreguntas].respuesta3,
   respuesta4: result[this.contadorPreguntas].respuesta4,
   correcta: result[this.contadorPreguntas].correcta,
   pos: result[this.contadorPreguntas].pos
 };*/

    this.guardarResultados();
    if (this.objPreguntas.pos > 0)
      this.contadorPreguntas--;

    //console.log("Mi conteo " + this.contadorPreguntas);

    // this.objPreguntas=this.arrayPreguntas[this.contadorPreguntas--];
    this.objPreguntas = this.arrayPreguntas[this.contadorPreguntas];
    //console.log("Mi conteo " + this.contadorPreguntas);
    let myObjStr = JSON.stringify(this.arrayPreguntas);
    //console.log(this.objPreguntasArray)
    //console.log(myObjStr)
    //console.log(myObjStr.hasOwnProperty("id"))

    //console.log(this.objPreguntas)

    //console.log("Mi conteo 2" + this.contadorPreguntas);



  }
  siguiente() {
    this.contadorPreguntas++;
    this.btnQuieroSeguir = true;
    if (this.contadorPreguntas >= this.arrayPreguntas.length) {
      this.btnTerminar = true;

    }

    this.objPreguntas = this.arrayPreguntas[this.contadorPreguntas];
    this.guardarResultados();



  }
  verificar() {
    this.contadorPreguntas;
    this.radiosPregunta=true;
    if (this.objPreguntas.crt == this.objPreguntas.ele) {
      if(this.acumulado==0){
        this.acumulado=100;
      }
      this.acumulado=this.acumulado*(2)
      document.getElementById("op" + this.objPreguntas.ele).classList.add('badge', 'badge-pill', 'badge-success');
      

     
      this.btnSiguiente = true;
      this.btnQuieroSeguir = false;
      this.btnTerminar = true;
      //this.btnCincuenta = true;
    } else {
      if (this.contadorPreguntas == 0) {
        this.contadorPreguntas++;
      }
      document.getElementById("op" + this.objPreguntas.ele).classList.add('badge', 'badge-pill', 'badge-danger');
      document.getElementById("op" + this.objPreguntas.crt).classList.add('badge', 'badge-pill', 'badge-success');


      this.btnTerminar = true;
      this.btnQuieroSeguir = false;
      this.btnCincuenta = false;
    }
    if (this.btnTerminar == true) {
      this.guardarResultados();

    }



  }
  guardarResultados() {

    this.objPreguntas.cat = this.arrayNumeroPreguntas[this.objPreguntas.pos].tipoPregunta;
    this.arrayPreguntas[this.objPreguntas.pos] = this.objPreguntas;

  }
  terminar() {


    this.llenarGrafico();
    this.btnRegresar = false;
    this.contadorPreguntas = 0;
    this.btnSiguiente = true;
    this.btnSiguienteInicio = false;
    this.intNumeroPregunta = 0;
    this.resultados = true;
    this.btnTerminar = false;
    this.btninicioNuevamente = true;
  }
  iniciarNuevamente() {
    if (location.pathname == "/quienquiereserfuncionario") {
      this.router.navigate(["quienquiereserfuncionarior"])
    } else {
      this.router.navigate(["quienquiereserfuncionario"])
    }
  }
  mostrarPregunta(numeroPregunta) {

    this.objPreguntas = this.arrayPreguntas[numeroPregunta];
    this.intPregunta = numeroPregunta + 1;
    this.mostrarRes = true;
    this.textoPregunta=this.intPregunta+": "+this.objPreguntas.pre;

  }
  llenarGrafico() {
    let jsonS = {
      instructions: [],


    };
    let jsonData2 = {
      instructions: [],


    };
    let jsonData3 = {
      instructions: [],


    };
    let correctaTipo1: number = 0;
    let incorrectaTipo1: number = 0;
    let correctaTipo2: number = 0;
    let incorrectaTipo2: number = 0;
    let correctaTipo3: number = 0;
    let incorrectaTipo3: number = 0;
    let arrayAsociativo = new Array();
    let validar = false;
    let tipoPregunta;
    this.arrayPreguntas = this.arrayPreguntas.filter(function (el) {
      return el != null;
    });
    for (var i = 0; i < this.arrayPreguntas.length; i++) {
      let preguntaAux: Preguntas;
      preguntaAux = this.arrayPreguntas[i];
      if (preguntaAux.crt == preguntaAux.ele) {
        tipoPregunta = "Correctas"
      } else {
        tipoPregunta = "Incorrectas"
      }

      for (let x = 0; x < jsonData3.instructions.length; x++) {
        if (jsonData3.instructions[x].name == preguntaAux.cat) {
          for (let y = 0; y < jsonData3.instructions[x].series.length; y++) {
            if (jsonData3.instructions[x].series[y].name == tipoPregunta) {
              jsonData3.instructions[x].series[y].value = jsonData3.instructions[x].series[y].value + 1
              validar = true
            }
          }
        }
      }
      if (validar == false) {
        jsonData3.instructions.push({
          name: preguntaAux.cat,
          series: [{
            name: tipoPregunta,
            value: 1
          }]
        })
      }
      validar = false
    }
    for (var i = 0; i < this.arrayPreguntas.length; i++) {
      let preguntaAux: Preguntas;

      preguntaAux = this.arrayPreguntas[i];
      if (preguntaAux.crt == preguntaAux.ele && preguntaAux.tp == 1) {
        correctaTipo1++
      

      } else if (preguntaAux.tp == 1) {
        incorrectaTipo1++
      }
      if (preguntaAux.crt == preguntaAux.ele && preguntaAux.tp == 2) {
        correctaTipo2++
      } else if (preguntaAux.tp == 2) {
        incorrectaTipo2++
      }
      if (preguntaAux.crt == preguntaAux.ele && preguntaAux.tp == 3) {
        correctaTipo3++
      } else if (preguntaAux.tp == 3) {
        incorrectaTipo3++
      }
    }
    jsonData2.instructions.push({
      name: "Correctas",
      value: correctaTipo1 + correctaTipo2+correctaTipo3

    });
    jsonData2.instructions.push({
      name: "Incorrectas",
      value: incorrectaTipo1 + incorrectaTipo2+incorrectaTipo3

    });
    jsonS.instructions.push({
      name: "Tipo 1",
      series: [{
        name: "Incorrectas",
        value: incorrectaTipo1
      }]

    })
    jsonS.instructions.push({
      series: [{
        name: "Correctas",
        value: correctaTipo1
      }], name: "Tipo 1",


    })

    jsonS.instructions.push({
      name: "Tipo 2",
      series: [{
        name: "Incorrectas",
        value: incorrectaTipo2
      }]

    })
    jsonS.instructions.push({
      name: "Tipo 2",
      series: [{
        name: "Correctas",
        value: correctaTipo2
      }]

    })
    jsonS.instructions.push({
      name: "Tipo 3",
      series: [{
        name: "Incorrectas",
        value: incorrectaTipo3
      }]

    })
    jsonS.instructions.push({
      series: [{
        name: "Correctas",
        value: correctaTipo3
      }], name: "Tipo 3",


    })
    this.data2 = jsonData2.instructions;
    this.data = jsonS.instructions;
    this.data3 = jsonData3.instructions
  }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  muestraGrafico() {
    this.mostrarGraficoBar = false
    this.mostrarGrafico2d = false
    this.mostrarGrafico2d3 = false

    if (this.index == 2) {
      this.mostrarGrafico2d = true

    } else if (this.index == 3) {
      this.mostrarGraficoBar = true
    }
    else if (this.index == 4) {
      this.mostrarGrafico2d3 = true
    }

  }
  unirPreguntas(seleccion: string) {
    let unir = []
    this.preguntasAMostrar = 30
    this.preguntaService.getIdUltimaPregunta(seleccion).valueChanges()
      .subscribe
      (data => {
        data.map((e: any = {}) => {
          this.objPreguntas2 = {
            id: e.id

          } as Preguntas;
          if (this.objPreguntas2.id <= this.preguntasAMostrar) {
            this.preguntasAMostrar = this.objPreguntas2.id - 1
          }
          let arrayAux = this.comunesService.numeroPreguntasTipo(0, this.objPreguntas2.id, this.preguntasAMostrar, seleccion);

          for (let i = 0; i < arrayAux.length; i++) {
            this.arrayNumeroPreguntas.push(arrayAux[i])
          }


        }

        )
      });
  }
  public numeroPreguntasTipo(minimoPreguntas: number,
    maximoPreguntas: number,
    totalPreguntas: number, tipoPregunta: string) {

    //var minimoPreguntas = 1;
    //var maximoPreguntas = 60;
    //var totalPreguntas= 55;//debe ser menor a maximo de preguntas
    if (minimoPreguntas > maximoPreguntas) {
      this.comunesService.ExceptionUsuario("Preguntas minimas no deben ser mayor a las maximas");
      throw this.comunesService.ExceptionUsuario;
    } else if (maximoPreguntas <= totalPreguntas) {
      this.comunesService.ExceptionUsuario("Total de preguntas debe ser menor o igual a maximo de preguntas");
      throw this.comunesService.ExceptionUsuario;
    }
    var jsonArg1 = new Object();
    var myArray = [];
    while (myArray.length < totalPreguntas) {
      var numeroAleatorio = Math.ceil(Math.random() * (maximoPreguntas - minimoPreguntas) + minimoPreguntas);
      var existe = false;
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].numeroAleatorio == numeroAleatorio) {
          debugger
          existe = true;
          break;
        }
      }
      if (!existe) {

        var fecha = new Date()
        var id = fecha.getTime()
        myArray[myArray.length] = { id, tipoPregunta, numeroAleatorio };
        //myArray.push({id,tipoPregunta,numeroAleatorio});
      }
      return myArray
    }

    //return this.afDB.database.ref('preguntas').orderByKey().endAt("id").limitToLast(1);
  }
  cincuentaCincuenta() {
    this.btnCincuenta=false;
    let opciones = [1, 2, 3, 4]
    this.comunesService.shuffle(opciones)
    let contador=0;
    for(let i=0;i<opciones.length;i++){
      if(this.objPreguntas.crt!=opciones[i]){
        if(opciones[i]==1)
        this.arrayOcultar.a=true
        else if (opciones[i]==2)
        this.arrayOcultar.b=true
        else if (opciones[i]==3)
        this.arrayOcultar.c=true
        else if (opciones[i]==4)
        this.arrayOcultar.d=true
        contador++;
        
      }
      if(contador==2){
        break;
      }
    }
  }


}
