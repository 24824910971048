import { Injectable, OnInit, Component } from '@angular/core';
import { PerfilesService } from './perfiles.service';
import { Perfiles } from '../clases/perfiles.model';
import { AutorizacionService } from './autorizacion.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate } from '@angular/router/src/utils/preactivation';

@Injectable({
  providedIn: 'root'
})

export class GuardianSuscripcionService implements OnInit,CanActivate {
  path: import("@angular/router").ActivatedRouteSnapshot[];
  route: import("@angular/router").ActivatedRouteSnapshot;
  perfiles:Perfiles;
esPremier:boolean=false;
logeado:boolean;
  constructor(private perfilesService:PerfilesService,
    private  angularFireAuth : AngularFireAuth,
    private autorizacionServices:AutorizacionService) {
    
    

    }
    canActivate(){
this.autorizacionServices.isLogeado().subscribe((respuesta)=>{ 
  this.esPremier=false;
  if(respuesta!=null){
      if( this.angularFireAuth.auth.currentUser.email!=null){
        this.perfilesService.obtenerPerfil(this.angularFireAuth.auth.currentUser.email).
        valueChanges() 
        .subscribe
        (data => {
          data.map((e: any = {}) => {
            this.perfiles = {
              cr: e.cr,
              fe: e.fe,
              nb: e.nb,
              rl: e.rl
              
            } as Perfiles;
          }
          )
          if(this.angularFireAuth.auth.currentUser.email!=null){
            if(this.perfiles.rl.toLowerCase()=='a'||this.perfiles.fe>=new Date().getTime()){
              this.esPremier=true;

            }else{
              this.esPremier=false;
            }

            }
  
        }
        
        );
      }
    }
  });
  //retorno

  return this.esPremier;
  }
    ngOnInit(){
      
    }
   }

