import { Injectable } from '@angular/core';
import { AngularFireAuth} from '@angular/fire/auth';
import { isError } from 'util';
import { Router } from '@angular/router';
import { PerfilesService } from './perfiles.service';
import { Perfiles } from '../clases/perfiles.model';
import { auth } from 'firebase';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AutorizacionService {
  isError:boolean=false;
  mensaje:string='';
  usuario:any={};
  key:string='';
  perfiles:Perfiles;
  usuPerfiles:Perfiles;
  email:string='';
  esPremier:boolean;
  constructor(private angularFireAuth : AngularFireAuth,
    private router:Router,private perfilesService:PerfilesService) { }

public login = (email,passwor)=>{
  this.email=email;
  this.angularFireAuth.auth.signInWithEmailAndPassword(email,passwor).
  then((respuesta)=>{
     
      
    this.mensaje='Usuario autorizado con éxito';
   
    
    this.isError=false;
    this.router.navigate(["/inicio"]);

  }
  
  ).catch((error)=>{

 this.isError=true;
 this.mensaje=error;
  })
  
}
public registro2 = (email,passwor,nombre)=>{
 if(nombre==null||nombre==''){
  this.isError=true;
  this.mensaje="Error el nombre es requerido";

 }else{
  this.angularFireAuth.auth.
  createUserWithEmailAndPassword(email,passwor)
  .then((respuesta)=>{
    this.perfiles= new Perfiles();
    this.perfiles.cr=this.angularFireAuth.auth.currentUser.email;
    this.perfiles.fe=new Date().getTime();
    this.perfiles.nb='';
    this.perfiles.rl="b";
    this.perfilesService.guardarPerfil(this.perfiles);
    
    alert("Usuario registrado con éxito");
    this.router.navigate(["/inicio"]);
    
    this.mensaje='Usuario registrado con éxito';

  }).catch((error)=>{
    this.isError=true;
 this.mensaje=error;
  }
  );
}
  }
 
  public registro = async(email,passwor,nombre)=>{
   
   await this.angularFireAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
     .then((respuesta)=>{
       this.perfiles= new Perfiles();
      
       this.perfiles.cr=this.angularFireAuth.auth.currentUser.email;
       this.perfiles.fe=new Date().getTime();
        this.perfiles.nb== this.angularFireAuth.auth.currentUser.email;
       this.perfiles.rl="b";
       //this.perfilesService.guardarPerfil(this.perfiles);
       //alert("Usuario registrado con éxito");
       this.router.navigate(["/inicio"]);
       
       this.mensaje='Usuario registrado con éxito';
   
     }).catch((error)=>{
       this.isError=true;
    this.mensaje=error;
     }

     );
  
     this.perfilesService.
      obtenerPerfil(this.perfiles.cr).snapshotChanges().subscribe(resultado=>
      {
        this.usuario=resultado.map((a) => {
          const key= a.key;
          const payload = a.payload;
          return { key,payload };
        });
        if(this.usuario.length==0){
           this.perfilesService.guardarPerfil(this.perfiles);
           location.reload();
        }
      }
      )
     }


  public  isLogeado(){

return this.angularFireAuth.authState.pipe();
  }
  restablecer(email:string):any{
    return this.angularFireAuth
    .auth
    .sendPasswordResetEmail(email)
    .then((respuesta)=>{
      this.mensaje='Se ha enviado un correo para retablecer la clave siga las intrucciones';
      this.isError=false;
    }).catch((error)=>{
      this.mensaje='Ocurrio un error verifique su email';
      this.isError=true;
    });
  }

  salir() {
    this.angularFireAuth
      .auth
      .signOut();
      location.reload();
      this.router.navigate(["/login"]);

  }

  googleSignIn() {
    return this.angularFireAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  // Método para obtener el usuario actual
  getUser(): Observable<any> {
    return this.angularFireAuth.authState;
  }



}
