import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AutorizacionService } from './servicios/autorizacion.service';
import { PerfilesService } from './servicios/perfiles.service';
import { Perfiles } from './clases/perfiles.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { SwUpdate } from '@angular/service-worker';
import { MessagingService } from './servicios/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent implements OnInit,OnDestroy{
  
  title = 'Entrenamiento comisión';
  listo=false;
  nombre='';
  plan='pagado';
  logeado=false;
  perfiles= new Perfiles();
  esAdmin:boolean=false;
  esPremier:boolean=false;
  mensaje:any={};
  
  constructor (public autorizacionServices:AutorizacionService
    ,private perfilesService:PerfilesService
    ,private  angularFireAuth : AngularFireAuth,
    private swUpdate: SwUpdate, private messagingService:MessagingService) {
      
    /*setTimeout(() => {
      this.listo=true;
    }, 3000);*/
    this.autorizacionServices.isLogeado()
    .subscribe((respuesta)=>{
      if(respuesta && respuesta.uid){
        this.logeado=true;
      }else{
        this.logeado=false;
  
      }
    },(error)=>{
      this.logeado=false;

    });

    this.autorizacionServices.isLogeado().subscribe((respuesta)=>{ 
      this.esAdmin=false;
      if(respuesta!=null){
          if( this.angularFireAuth.auth.currentUser.email!=null){
            this.perfilesService.obtenerPerfil(this.angularFireAuth.auth.currentUser.email).
            valueChanges() 
            .subscribe
            (data => {
              data.map((e: any = {}) => {
                this.perfiles = {
                  cr: e.cr,
                  fe: e.fe,
                  nb: e.nb,
                  rl: e.rl
                  
                } as Perfiles;
              }
              )
              if(this.angularFireAuth.auth.currentUser.email!=null){
                if(this.perfiles.rl.toLowerCase()=='a'){
                  this.esAdmin=true;
    
                }else{
                  this.esAdmin=false;
                }
    
                }
      
            }
            
            );
          }
        }
      });
      this.autorizacionServices.isLogeado().subscribe((respuesta)=>{ 
        this.esPremier=false;
        if(respuesta!=null){
            if( this.angularFireAuth.auth.currentUser.email!=null){
              this.perfilesService.obtenerPerfil(this.angularFireAuth.auth.currentUser.email).
              valueChanges() 
              .subscribe
              (data => {
                data.map((e: any = {}) => {
                  this.perfiles = {
                    cr: e.cr,
                    fe: e.fe,
                    nb: e.nb,
                    rl: e.rl
                    
                  } as Perfiles;
                }
                )
                if(this.angularFireAuth.auth.currentUser.email!=null){
                  if(this.perfiles.rl.toLowerCase()=='a'||this.perfiles.fe>=new Date().getTime()){
                    this.esPremier=true;
      
                  }else{
                    this.esPremier=false;
                  }
      
                  }
        
              }
              
              );
            }
          }
        });
       // this.messagingService.getPermission();
      //  this.messagingService.receiveMessage();


    }

      ngOnInit(): void {
        if (this.swUpdate.isEnabled) {
          this.swUpdate.available.subscribe((siguiente) => {
            window.location.reload();
    
          })
        }
        this.mensaje=this.messagingService.currentMessage;
    }
    ngOnDestroy(): void{
     
    }
    public getAdmin(){
  if(this.autorizacionServices.email!=''){
  this.perfilesService.obtenerPerfil(this.autorizacionServices.email).
      valueChanges()
      
      .subscribe
      (data => {
        data.map((e: any = {}) => {
          this.perfiles = {
            cr: e.cr,
            fe: e.fe,
            nb: e.nb,
            rl: e.rl,
            
          } as Perfiles;
        }
        )
      }); 
      if(this.perfiles.rl.toLowerCase()=='a'){
        this.esAdmin=true;
      }
    }
      return this.esAdmin ;
    }

}



